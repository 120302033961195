




















































































































































































































































































import { defineComponent } from "@vue/composition-api";

import LastUpdatedAt from "@/components/LastUpdatedAt.vue";
import PageHeader from "@/components/PageHeader.vue";
import setPageTitle from "@/compositions/setPageTitle";

export default defineComponent({
  name: "Privacy",

  components: {
    LastUpdatedAt,
    PageHeader,
  },

  setup() {
    setPageTitle("views.privacy.pageTitle");
  },
});
